@import "src/themes/mojito/styles/index.scss";
.PickerControlled {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-bottom: space(1.5);
  gap: space(0.5);

  @include md {
    gap: space(0.75);
  }

  &__list-background {
    display: flex;
    gap: space(0.5);
    padding-left: 0;
    background-color: getvar($colors, "neutral", "100");
    padding: space();
    border-radius: space(0.5);
    flex-wrap: wrap;

    @include md {
      gap: space(1);
    }
  }

  &__item {
    background-color: getvar($colors, "neutral", "50");
    border: solid 1px getvar($colors, "neutral", "200");
    border-radius: space(0.75);
    height: space(2.5);
    list-style: none;
    margin-bottom: 0;
    width: space(2.5);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include md {
      height: space(3);
      width: space(3);
    }

    &--active {
      @extend .u-active;
    }
  }

  &__icon {
    svg {
      height: space(1.5);
      width: space(1.5);

      @include md {
        height: space(2);
        width: space(2);
      }
    }

    div {
      display: flex;
    }
  }
}
